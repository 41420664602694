
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class MainImage extends Vue {
  @Prop() claim!: string;
  @Prop() siteName!: string;
  @Prop() logo!: any;
  @Prop() isassociato!: boolean;
  public prefix: string = "Istituto Vendite Giudiziarie";
}
